import { makeStyles, Theme } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

export const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    padding: theme.spacing(2, 0)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    padding: theme.spacing(1, 0)
  },
  headingBold: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20
  },
  details: {
    flexDirection: 'column',
    borderRadius: '4px',
    backgroundColor: grey[100],
    margin: theme.spacing(0, 2, 2, 2)
  },
  column: {
    flex: 'auto',
    alignSelf: 'center',
    '&:last-of-type': {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  center: {
    marginBottom: '0',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '30vh'
  },
  toolbar: {
    width: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(3)
  },
  typeSelect: {
    flexBasis: '20%',
    minWidth: theme.spacing(20)
  },
  actions: {
    padding: theme.spacing(2, 3),
    justifyContent: 'left'
  },
  textArea: {
    width: '100%'
  },
  textAreaInput: {
    fontFamily: 'monospace',
    fontSize: '0.8rem'
  },
  textCenter: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  menuItem: {
    textTransform: 'none'
  },
  messagePanelDetails: {
    flexDirection: 'column'
  },
  predefinedCommandsDropdown: {
    width: '25%',
    marginBottom: '1em'
  }
}))
