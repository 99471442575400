import React, { useEffect, useRef, useState } from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { useStyles } from './styles'
import { CircularProgress, MenuItem, Select } from '@material-ui/core'

interface Props {
  onPublish: (message: string) => void
  onSendSMS: (message: string) => void
  loading: boolean
}

const PublishPanel: React.FC<Props> = props => {
  const classes = useStyles()
  const [message, setMessage] = useState<string>('')
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [shouldFocus, setShouldFocus] = useState(false)
  const ref = useRef<HTMLTextAreaElement>(null)

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value)
  }

  useEffect(() => {
    if (ref.current && shouldFocus) {
      ref.current.focus()
      setShouldFocus(false)
    }
  }, [shouldFocus])

  const predefinedCommands = [
    {
      value:
        'AT+GTUPC=gv57,3,30,0,1,0,http://queclink-assets.s3.us-west-2.amazonaws.com/1nce_goldenconfig.ini,1,,1,0000001F,,3,0001$',
      text: '1nce Factory Config'
    },
    {
      value: 'AT+GTTOW=gv57,0,5,1,30,0,0,0,0,1,3,1,,,,,,,,,FFFF$',
      text: 'Shock sensitivity: low'
    },
    {
      value: 'AT+GTTOW=gv57,0,5,1,30,0,0,0,0,1,3,4,,,,,,,,,FFFF$',
      text: 'Shock sensitivity: medium'
    },
    {
      value: 'AT+GTTOW=gv57,0,5,1,30,0,0,0,0,1,3,9,,,,,,,,,FFFF$',
      text: 'Shock sensitivity: high'
    }
  ]

  return (
    <Paper square>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <Typography variant="h5">PUBLISH</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.messagePanelDetails}>
          <Select
            open={dropdownOpen}
            onClose={() => setDropdownOpen(false)}
            onOpen={() => setDropdownOpen(true)}
            value="0"
            onChange={event => {
              setMessage(String(event.target.value))
              setShouldFocus(true)
            }}
            className={classes.predefinedCommandsDropdown}
          >
            <MenuItem value="0" className={classes.menuItem}>
              Select a command...
            </MenuItem>
            {predefinedCommands.map((item, index) => (
              <MenuItem
                value={item.value}
                key={index}
                className={classes.menuItem}
              >
                {item.text}
              </MenuItem>
            ))}
          </Select>
          <TextField
            id="standard-multiline-static"
            multiline
            minRows="3"
            variant="outlined"
            placeholder="Type message..."
            className={classes.textArea}
            inputProps={{ className: classes.textAreaInput }}
            onChange={handleChange}
            value={message}
            inputRef={ref}
          />
        </ExpansionPanelDetails>
        <Divider />
        <ExpansionPanelActions className={classes.actions}>
          <Button size="small" onClick={() => setMessage('')}>
            Clear
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="outlined"
            disabled={message.length === 0 || props.loading}
            style={{ marginLeft: '8px' }}
            onClick={() => props.onSendSMS(message)}
          >
            SMS Publish
          </Button>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            disabled={message.length === 0 || props.loading}
            style={{ marginLeft: '8px' }}
            onClick={() => props.onPublish(message)}
          >
            Socket Publish
          </Button>
          {props.loading && <CircularProgress size={24} color="secondary" />}
        </ExpansionPanelActions>
      </ExpansionPanel>
    </Paper>
  )
}

export default PublishPanel
